import { Button, Form, FormProps, Input, Select } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

import TextArea from "antd/es/input/TextArea";
import "./registerForm.css";
import { useAuth } from "../../../context/AuthProvider";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { apiInstance } from "../../../api/axios";
import { useTranslation } from "react-i18next";
import { Language } from "../selectLanguage/selectLanguage";

const { Option } = Select;

type FieldType = {
  name?: string;
  email?: string;
  country?: string;
  password?: string;
  knowFromUs?: string;
};

const userLanguage = {
  en: "English",
  ru: "Russian",
  es: "Spanish",
};

export default function RegisterForm() {
  const { t } = useTranslation();
  const [getValueStorage, setValueStorage] = useLocalStorage();
  const { setUser } = useAuth();

  const navigate = useNavigate();

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    confirmForm(values);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.error("Failed:", errorInfo);
  };

  const confirmForm = async (values: FieldType) => {
    const lang: Language = await getValueStorage("LANGUAGE");
    apiInstance({
      method: "post",
      url: "/public/chat",
      data: {
        customerData: { ...values, user_language: userLanguage[lang || "en"] },
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setValueStorage("chatID", res.data.id);
          setValueStorage("MigratorUserName", values.name);
          setUser([res.data.id]);
          navigate(`/`, { replace: true });
        }
      })
      .catch((e) => console.error(e));
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item<FieldType>
        hasFeedback
        label={t("name") + "*"}
        name="name"
        rules={[{ required: true, min: 2, max: 40, message: t("yourName") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FieldType>
        hasFeedback
        label={t("email") + "*"}
        name="email"
        rules={[
          {
            required: true,
            min: 2,
            max: 60,
            message: t("yourEmail"),
            type: "email",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FieldType>
        hasFeedback
        label={t("country") + "*"}
        name="country"
        rules={[{ required: true, message: t("selectCountry") }]}
      >
        <Select placeholder={t("yourCountry")} allowClear>
          <Option value="en">{t("usa")}</Option>
          <Option value="es">{t("spain")}</Option>
          <Option value="ru">{t("russia")}</Option>
        </Select>
      </Form.Item>

      <Form.Item<FieldType>
        name="knowFromUs"
        label={t("knowFromUs")}
        rules={[{ required: false }]}
      >
        <TextArea rows={4} maxLength={1000} />
      </Form.Item>

      <Form.Item
        className="buttonStartBox"
        style={{ width: "100%" }}
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Button className="buttonStart" type="default" htmlType="submit">
          {t("start")}
        </Button>
      </Form.Item>
    </Form>
  );
}
