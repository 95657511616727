import FormBox from "../../components/formBox";
import "./register.css";

export default function Register() {
  return (
    <div className="register">
      <div className="imageBox">
        <img
          className="mainImage"
          alt=""
          src={require("../../assets/images/registerMain.png")}
          width="100%"
          height="100%"
        />
      </div>
      <FormBox />
    </div>
  );
}
