import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Logo from "../../../../assets/images/logo";
import "./style.css";

export default function Message({ message }: any) {
  const roleStyle = (role: string) => {
    return role !== "user" ? "systemMesg" : "userMesg";
  };

  return (
    <li className={`${roleStyle(message.role)} message`}>
      {message.role !== "user" && (
        <div className="logoBox">
          <Logo width="95" height="19" />
        </div>
      )}
      <Markdown
        remarkPlugins={[remarkGfm]}
        className={`${roleStyle(message.role)}-message message-text`}
      >
        {typeof message.content === "string"
          ? message?.content
          : message.content[0].text}
      </Markdown>
    </li>
  );
}
