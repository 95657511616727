import { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import Divider from "../divider";
import "./formBox.css";
import RegisterForm from "./registerForm/registerForm";
import SelectLanguage from "./selectLanguage/selectLanguage";
import { useTranslation } from "react-i18next";

export default function FormBox() {
  const [language, setSelectLanguage] = useState<any>();
  const { t } = useTranslation();

  const [getValueStorage, setValueStorage] = useLocalStorage();

  const selectLanguage = (lang: string) => {
    setValueStorage("LANGUAGE", lang);
    setSelectLanguage(lang);
  };

  return (
    <div className="formBox">
      <div className="formWrapper">
        <div className="mainImageWrapper">
          <img
            className="mainImage"
            alt=""
            src={require("../../assets/images/Al-migrator.png")}
            width="100%"
            height="100%"
          />
        </div>

        <Divider classes={["divider"]} />

        <h1 className="mainTitle">{t("startConversation")}</h1>

        {language ? (
          <RegisterForm />
        ) : (
          <SelectLanguage selectLanguage={selectLanguage} />
        )}
      </div>
    </div>
  );
}
