import React, { useEffect, useRef, useState } from "react";
import { fetchAccessToken } from "../../api/get-access-token";
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  TaskType,
  VoiceEmotion,
} from "@heygen/streaming-avatar";
import "./style.css";
import useLocalStorage from "../../hooks/useLocalStorage";

type Languages = "ru" | "en" | "es" | null | undefined;

const avatarLanguageSettings = {
  ru: {
    voice_id: "bc69c9589d6747028dc5ec4aec2b43c3",
    welcomeText: (name: string) =>
      `Добро пожаловать${name}! Мы – команда Migrator, и мы помогаем сделать вашу мечту об Америке реальностью. Профессионально занимаемся оформлением виз и поддержкой на всех этапах. Позвольте нам взять заботы на себя, чтобы вы могли сосредоточиться на новых возможностях!`,
  },
  en: {
    voice_id: "1bd001e7e50f421d891986aad5158bc8",
    welcomeText: (name: string) =>
      `Welcome ${name}! We are the Migrator team, and we help make your dream of America come true. We professionally handle visa processing and support at all stages. Let us take care of the hassle so you can focus on new opportunities!`,
  },
  es: {
    voice_id: "2f84d49c51a741f3a5be283b0fc4f94c",
    welcomeText: (name: string) =>
      `¡Bienvenido ${name}! omos el equipo de Migrator y ayudamos a hacer realidad su sueño americano. Brindamos procesamiento de visas profesional y soporte en todas las etapas. ¡Déjanos ocuparnos de tus preocupaciones para que puedas concentrarte en nuevas oportunidades!`,
  },
};

export default function InteractiveAvatar() {
  const [getValueStorage, setValueStorage] = useLocalStorage();
  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [avatarImage, setAvatarImage] = useState<boolean>(false);

  const mediaStream = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const avatar = useRef<StreamingAvatar | null>(null);

  async function startSession() {
    setIsLoadingSession(true);
    const newToken = await fetchAccessToken();

    const userName = await getValueStorage("MigratorUserName");
    const lang = (await getValueStorage("LANGUAGE")) as Languages;
    const { voice_id, welcomeText } = avatarLanguageSettings[lang || "en"];

    avatar.current = new StreamingAvatar({
      token: newToken,
    });
    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, () => {
      console.log("Avatar started talking");
    });
    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, () => {
      console.log("Avatar stopped talking");
      setValueStorage("avatarIsShowing", true);
      endSession();
    });
    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log("Stream disconnected");
      endSession();
    });
    avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
      console.log(">>>>> Stream ready");
      setStream(event.detail);
      handleSpeak(welcomeText(userName));
    });
    try {
      await avatar.current.createStartAvatar({
        quality: AvatarQuality.Low,
        avatarName: "Kayla-incasualsuit-20220818",
        voice: {
          rate: 1,
          emotion: VoiceEmotion.EXCITED,
          voiceId: voice_id,
        },
        language: lang ?? "en",
      });
      //   await avatar.current?.startVoiceChat();
    } catch (error) {
      console.error("Error starting avatar session:", error);
    } finally {
      setIsLoadingSession(false);
    }
  }

  const avatarDisable = async () => {
    getValueStorage("avatarIsShowing").then((status) => {
      if (status) {
        setTimeout(() => setAvatarImage(status), 0);
      }
    });
  };

  async function endSession() {
    await avatar.current?.stopAvatar();
    setStream(undefined);
    avatarDisable();
  }

  async function handleSpeak(text: string) {
    if (!avatar.current) {
      console.error("Avatar API not initialized");
      return;
    }
    await avatar.current
      .speak({ text, task_type: TaskType.REPEAT })
      .catch((e) => {
        console.error(e.message);
      });
  }

  useEffect(() => {
    avatarDisable();
    getValueStorage("avatarIsShowing").then((status) => {
      if (!status) {
        startSession();
      }
    });

    return () => {
      endSession();
    };
  }, []);

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream;
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current?.play();
        console.log("Playing");
      };
    }
  }, [mediaStream, stream]);

  useEffect(() => {
    if (!stream || !mediaStream || !canvasRef) return;

    const video = mediaStream.current;
    const canvas = canvasRef.current;

    if (!canvas || !video) return;

    const applyChromaKey = () => {
      const ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      if (!ctx) return;
      if (video.videoWidth === 0 || video.videoHeight === 0) return;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const frame = ctx?.getImageData(0, 0, canvas?.width, canvas?.height);

      const length = frame.data.length;

      for (let i = 0; i < length; i += 4) {
        const r = frame.data[i];
        const g = frame.data[i + 1];
        const b = frame.data[i + 2];
        if (g > 200 && r < 100 && b < 100) {
          frame.data[i + 3] = 0;
        }
      }

      ctx.putImageData(frame, 0, 0);

      requestAnimationFrame(applyChromaKey);
    };

    video.addEventListener("loadeddata", () => {
      applyChromaKey();
    });

    return () => {
      video.removeEventListener("play", applyChromaKey);
    };
  }, [stream]);

  const roleStyle = (status: any) => {
    return status ? "video-avatar-showed" : "";
  };

  return (
    <div className="video-avatar-box">
      {stream ? (
        <div className={`${roleStyle(avatarImage)} video-box`}>
          <video
            ref={mediaStream}
            autoPlay
            playsInline
            className={`video-avatar`}
          >
            <track kind="captions" />
          </video>
          <canvas ref={canvasRef}></canvas>
        </div>
      ) : (
        <div>
          <img
            className={`${roleStyle(avatarImage)} video-avatar-image`}
            alt="AvatarImage"
            src={require("../../assets/images/ia_avatar.png")}
          />
        </div>
      )}
      {/* <button onClick={startSession}>Start Session</button>
      <button onClick={endSession}>endSession Session</button> */}
    </div>
  );
}
