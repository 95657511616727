import React from "react";
import "./divider.css";

interface IDivider {
  classes: string[];
}

export default function Divider({ classes = [] }: IDivider) {
  return <div className={[...classes, "divider"].join(" ")} />;
}
